import { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { QueryFlightParams } from '../../../model/query-flight-params';
import isNil from 'lodash-es/isNil';
import eq from 'lodash-es/eq';
import capitalize from 'lodash-es/capitalize';
import isEmpty from 'lodash-es/isEmpty';
import includes from 'lodash-es/includes';
import isNull from 'lodash-es/isNull';
import isEqual from 'lodash-es/isEqual';
import replace from 'lodash-es/replace';
import isUndefined from 'lodash-es/isUndefined';
import split from 'lodash-es/split';
import each from 'lodash-es/each';
import clone from 'lodash-es/clone';
import filter from 'lodash-es/filter';
import remove from 'lodash-es/remove';
import trim from 'lodash-es/trim';
import { Flight } from '../../../model/flight';
import { ContactData } from '../../../model/contact-data';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute, Router } from '@angular/router';
import { HowItWorksDialogComponent } from '../../modal/how-it-works-dialog/how-it-works-dialog.component';
import { MatDialog } from '@angular/material';
import { isPlatformBrowser } from '@angular/common';
import * as moment from 'moment';
import { DataService } from '../../../../shared/shared-data.service';
import { interval, of, Subject } from 'rxjs';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import * as atob from 'atob';
import { debounceTime, first, map, switchMap, takeUntil, takeWhile } from 'rxjs/operators';
import { ConstantService } from '../../../services/constant.service';
import { TrackingEvent, TrackingService } from '../../../services/tracking.service';
import { ApplicationEventBroadcaster } from '../../../../shared/application.event.broadcaster';
import { NotifyService } from '../../../services/notify.service';
import { getElementById } from '../../../../shared/util/DOM-util';
import { environment } from '../../../../environments/environment';
import { SearchboxService } from '../searchbox/searchbox.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GetQuoteCompleteDialogComponent } from "../../modal/get-quote-complete-dialog/get-quote-complete-dialog.component";
import { ReCaptchaV3Service } from "ng-recaptcha";
export function setTimeout$(cb, timer) {
    of(true).pipe(debounceTime(timer ? timer : 0), first()).subscribe(cb);
}
// declare let fbq: Function;
var PHONE_KEY = makeStateKey('phone');
var PHONE_VISIBLE_KEY = makeStateKey('phoneVisible');
var SearchboxNewGenerationComponent = /** @class */ (function () {
    function SearchboxNewGenerationComponent(formBuilder, searchboxService, cookieService, route, dialog, platformId, router, dataService, state, constantService, appStorage, trackingService, broadcaster, deviceService, notifyService, recaptchaV3Service) {
        this.formBuilder = formBuilder;
        this.searchboxService = searchboxService;
        this.cookieService = cookieService;
        this.route = route;
        this.dialog = dialog;
        this.platformId = platformId;
        this.router = router;
        this.dataService = dataService;
        this.state = state;
        this.constantService = constantService;
        this.appStorage = appStorage;
        this.trackingService = trackingService;
        this.broadcaster = broadcaster;
        this.deviceService = deviceService;
        this.notifyService = notifyService;
        this.recaptchaV3Service = recaptchaV3Service;
        this.activeForm = this.roundTripForm;
        this.queryFlightParams = new QueryFlightParams;
        this.flight = new Flight;
        this.flight1 = new Flight;
        this.flight2 = new Flight;
        this.locationData = [];
        this.formSending = false;
        this.contactData = new ContactData();
        this.phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
        this.showAddFlightButton = true;
        this.showThirdFlight = false;
        this.focusOut = false;
        this.mustSubmit = false;
        this.phoneVisible = false;
        this.clickTripType = true;
        this.flightSubmitted = false;
        this.showSearchbox = false;
        this.destroy$ = new Subject();
        this.cdnPath = environment.cdnPath;
    }
    SearchboxNewGenerationComponent.prototype.ngOnInit = function () {
        this.dataService.sendData({ type: 'showSpinner', data: false });
        this.isMobileOrTablet = this.deviceService.isMobile() || this.deviceService.isTablet();
        this.initPhone();
        this.normalFlight = true;
        this.searchFormValid = true;
        this.queryPanelValid = true;
        this.initTripContent();
        this.queryFlightParams.tripType = 'Round';
        this.submitBtnText = 'Get Quote';
        this.initForms();
        // this.initFields();
        this.initAutocompleteForAll();
    };
    SearchboxNewGenerationComponent.prototype.routeTo = function (path) {
        var _this = this;
        setTimeout(function () { _this.router.navigateByUrl(path); console.log('Test ' + path); }, 0);
    };
    Object.defineProperty(SearchboxNewGenerationComponent.prototype, "header", {
        get: function () {
            return isNil(this.constantService.getConstant('_searchBlockHeader')) ? undefined : this.constantService.getConstant('_searchBlockHeader').replace('Business', this.landingClass);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SearchboxNewGenerationComponent.prototype, "landingClass", {
        get: function () {
            return isNil(this.router) || isNil(this.router.url) || !this.router.url.includes('first-class') ? 'Business' : 'First';
        },
        enumerable: true,
        configurable: true
    });
    SearchboxNewGenerationComponent.prototype.autoFillFromParam = function (name) {
        var _this = this;
        if (isNil(name) || isEmpty(name)) {
            return;
        }
        this.searchboxService.getLocations(name.replace('-', ' ')).subscribe(function (response) {
            if (response.length !== 0) {
                _this.flight.to = response[0];
            }
        }, function (error) {
            //    do nothing
        });
    };
    SearchboxNewGenerationComponent.prototype.initPhone = function () {
        var _this = this;
        if (!isNil(this.state.get(PHONE_KEY, null))) {
            this.phone = this.state.get(PHONE_KEY, null);
        }
        if (!isNil(this.state.get(PHONE_VISIBLE_KEY, null))) {
            this.phoneVisible = this.state.get(PHONE_VISIBLE_KEY, null);
        }
        this.subscription = this.dataService.getData()
            .subscribe(function (sharedData) {
            if (eq(sharedData.type, 'phone')) {
                _this.phone = sharedData.data;
            }
            if (eq(sharedData.type, 'phoneVisible')) {
                _this.phoneVisible = sharedData.data;
            }
            if (eq(sharedData.type, 'autoFillTo')) {
                _this.autoFillFromParam(sharedData.data);
            }
        });
    };
    SearchboxNewGenerationComponent.prototype.initTripContent = function () {
        this.queryFlightParams.cabin = 'BUSINESS';
        if (!isEmpty(this.appStorage.getItem('WF_CABIN_TYPE'))) {
            this.queryFlightParams.cabin = this.appStorage.getItem('WF_CABIN_TYPE');
        }
        this.queryFlightParams.passengers = 1;
        if (!isEmpty(this.appStorage.getItem('WF_ADULT'))) {
            this.queryFlightParams.passengers = Number(this.appStorage.getItem('WF_ADULT'));
        }
    };
    /* Start init autocomplete */
    SearchboxNewGenerationComponent.prototype.initAutocompleteForAll = function () {
        /* Init autocomplete for round trip */
        this.filteredFromData = this.initAutocomplete(this.roundTripForm, 'flyingFrom');
        this.filteredToData = this.initAutocomplete(this.roundTripForm, 'flyingTo');
        /* Init autocomplete for one way */
        this.filteredFromOneWayData = this.initAutocomplete(this.oneWayForm, 'flyingFrom');
        this.filteredToOneWayData = this.initAutocomplete(this.oneWayForm, 'flyingTo');
        /* Init autocomplete for multi city */
        this.filteredFromMultiCityData = this.initAutocomplete(this.multiCityForm, 'flyingFrom');
        this.filteredToMultiCityData = this.initAutocomplete(this.multiCityForm, 'flyingTo');
        this.filteredFrom1MultiCityData = this.initAutocomplete(this.multiCityForm, 'flyingFrom1');
        this.filteredTo1MultiCityData = this.initAutocomplete(this.multiCityForm, 'flyingTo1');
        this.filteredFrom2MultiCityData = this.initAutocomplete(this.multiCityForm, 'flyingFrom2');
        this.filteredTo2MultiCityData = this.initAutocomplete(this.multiCityForm, 'flyingTo2');
    };
    SearchboxNewGenerationComponent.prototype.initAutocomplete = function (form, contrl) {
        var _this = this;
        return form.get(contrl).valueChanges
            .pipe(debounceTime(200), switchMap(function (value) {
            if (!_this.mustSubmit && isNil(value.match(/\D+\(\w+\)/i)) && !isEmpty(value)) {
                return _this.searchboxService.getLocations(value.trim())
                    .pipe(map(function (response) {
                    if (response.length === 0 && !isNull(value) && !isEmpty(value)) {
                        return Array.of('No cities found');
                    }
                    else {
                        return response;
                    }
                }));
            }
            else {
                return [];
            }
        }));
    };
    /* End init autocomplete */
    SearchboxNewGenerationComponent.prototype.initForms = function () {
        this.initRoundTripForm();
        this.initOneWayForm();
        this.initMultiCityForm();
        this.initQueryPanelForm();
        this.activeForm = this.roundTripForm;
    };
    SearchboxNewGenerationComponent.prototype.initRoundTripForm = function () {
        var _this = this;
        this.roundTripForm = this.formBuilder.group({
            flyingFrom: ['', [Validators.required, this.noWhitespaceValidator]],
            flyingTo: ['', [Validators.required, this.noWhitespaceValidator]],
            departDate: ['', Validators.required],
            returnDate: ['', Validators.required]
        });
        this.roundTripForm.valueChanges
            .pipe(debounceTime(100))
            .subscribe(function (data) {
            if (_this.roundTripForm.valid && _this.mustSubmit) {
                _this.submitSearch();
            }
        });
    };
    SearchboxNewGenerationComponent.prototype.initOneWayForm = function () {
        var _this = this;
        this.oneWayForm = this.formBuilder.group({
            flyingFrom: ['', [Validators.required, this.noWhitespaceValidator]],
            flyingTo: ['', [Validators.required, this.noWhitespaceValidator]],
            departDate: ['', Validators.required]
        });
        this.oneWayForm.valueChanges
            .pipe(debounceTime(100))
            .subscribe(function (data) {
            if (_this.oneWayForm.valid && _this.mustSubmit) {
                _this.submitSearch();
            }
        });
    };
    SearchboxNewGenerationComponent.prototype.initMultiCityForm = function () {
        var _this = this;
        this.multiCityForm = this.formBuilder.group({
            flyingFrom: ['', [Validators.required, this.noWhitespaceValidator]],
            flyingTo: ['', [Validators.required, this.noWhitespaceValidator]],
            flyingFrom1: ['', [Validators.required, this.noWhitespaceValidator]],
            flyingTo1: ['', [Validators.required, this.noWhitespaceValidator]],
            flyingFrom2: [''],
            flyingTo2: [''],
            departDate: ['', Validators.required],
            departDate1: ['', Validators.required],
            departDate2: ['']
        });
        this.multiCityForm.valueChanges
            .pipe(debounceTime(100))
            .subscribe(function (data) {
            if (_this.multiCityForm.valid && _this.thirdFlightValid() && _this.mustSubmit) {
                _this.submitSearch();
            }
        });
    };
    SearchboxNewGenerationComponent.prototype.thirdFlightValid = function () {
        return this.showThirdFlight
            ? this.showThirdFlight
                && !isEmpty(this.multiCityForm.get('flyingFrom2').value)
                && !isEmpty(this.multiCityForm.get('flyingTo2').value)
                && !isEmpty(this.multiCityForm.get('departDate2').value)
            : true;
    };
    SearchboxNewGenerationComponent.prototype.changeMustSubmit = function (mustSubmit) {
        this.mustSubmit = mustSubmit;
    };
    SearchboxNewGenerationComponent.prototype.initQueryPanelForm = function () {
        this.queryPanelForm = this.formBuilder.group({
            firstName: ['', Validators.compose([Validators.required, this.noWhitespaceValidator])],
            email: ['', Validators.compose([Validators.required, Validators.email])],
            phone: ['', Validators.compose([
                    Validators.required,
                    Validators.pattern('^[(]\\d{3}[)][\\s]\\d{3}[-]\\d{4}$'),
                    this.phoneValidator()
                ])]
        });
    };
    SearchboxNewGenerationComponent.prototype.phoneValidator = function () {
        var _this = this;
        return function (control) {
            if (_this.phoneSelect && !isEmpty(control.value) && control.value.startsWith('(1')
                && _this.phoneSelect.getCountryData().iso2 === 'us') {
                return { phoneFormatError: true };
            }
            else {
                return null;
            }
        };
    };
    SearchboxNewGenerationComponent.prototype.initDatepicker = function () {
        this.firstDayOfWeek = 7;
        var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
        this.displayMonths = width >= 768 ? 2 : 1;
        this.navigation = 'arrows';
        var now = new Date();
        this.maxDate = this.prepareDate(moment(now).add(3, 'y').toDate());
        this.minDateDepart = this.prepareDate(now);
        if (this.isEmptyDate(this.flight.departDate)) {
            this.minDateReturn = this.prepareDate(moment(now).add(1, 'd').toDate());
            this.minDateDepart1 = this.prepareDate(moment(now).add(1, 'd').toDate());
            this.minDateDepart2 = this.prepareDate(moment(now).add(1, 'd').toDate());
        }
    };
    SearchboxNewGenerationComponent.prototype.initFields = function () {
        var _this = this;
        this.searchboxService.getInitQueryFlightParams().subscribe(function (response) {
            _this.queryFlightParams = response;
            if (eq(response.cabin, 'BLANK')) {
                _this.queryFlightParams.cabin = 'BUSINESS';
            }
            _this.flight.from = response.flights[0].from;
            _this.flight.to = response.flights[0].to;
            _this.flight.departDate = _this.prepareDate(moment(response.flights[0].departDate).toDate());
            _this.returnDate = _this.prepareDate(moment(response.returnDate).toDate());
            _this.minDateReturn = _this.prepareDate(moment(response.flights[0].departDate).add(1, 'days').toDate());
            if (response.flights.length > 1) {
                _this.flight1.from = response.flights[1].from;
                _this.flight1.to = response.flights[1].to;
                _this.flight1.departDate = _this.prepareDate(moment(response.flights[1].departDate).toDate());
                _this.minDateDepart1 = _this.prepareDate(moment(response.flights[0].departDate).toDate());
            }
            if (response.flights.length === 3) {
                _this.flight2.from = response.flights[2].from;
                _this.flight2.to = response.flights[2].to;
                _this.flight2.departDate = _this.prepareDate(moment(response.flights[2].departDate).toDate());
                _this.minDateDepart2 = _this.prepareDate(moment(response.flights[1].departDate).toDate());
                _this.showThirdFlight = true;
                _this.showAddFlightButton = false;
            }
            _this.setActiveForm(response.tripType);
            _this.initFlightDataNow = true;
            if (isPlatformBrowser(_this.platformId)) {
                _this.initDatepicker();
            }
        }, function (error) {
            if (isPlatformBrowser(_this.platformId)) {
                _this.initDatepicker();
            }
        });
    };
    SearchboxNewGenerationComponent.prototype.notEmptyFlight = function (flight) {
        return this.isNotEmpty(flight.from) && this.isNotEmpty(flight.to) && this.isNotEmpty(flight.departDate);
    };
    SearchboxNewGenerationComponent.prototype.getFlyingFieldValue = function (cookieName) {
        return this.existInCookie(cookieName) ? atob(this.appStorage.getItem(cookieName)) : '';
    };
    SearchboxNewGenerationComponent.prototype.getFlyingFieldDateValue = function (cookieName) {
        return this.existInCookie(cookieName) ? this.prepareDate(moment(this.appStorage.getItem(cookieName)).toDate()) : null;
    };
    SearchboxNewGenerationComponent.prototype.setActiveForm = function (tripType) {
        if (eq(tripType, 'Round')) {
            this.activeForm = this.roundTripForm;
        }
        else if (eq(tripType, 'OneWay')) {
            this.activeForm = this.oneWayForm;
        }
        else {
            this.activeForm = this.multiCityForm;
        }
    };
    SearchboxNewGenerationComponent.prototype.existInCookie = function (name) {
        return !(isUndefined(this.appStorage.getItem(name)) || isNull(this.appStorage.getItem(name)) || isEmpty(this.appStorage.getItem(name)));
    };
    SearchboxNewGenerationComponent.prototype.prepareDate = function (date) {
        return {
            day: date.getDate(),
            month: date.getMonth() === 12 ? 1 : date.getMonth() + 1,
            year: date.getFullYear(),
        };
    };
    SearchboxNewGenerationComponent.prototype.prepareCityName = function (fullNameAirport) {
        var result;
        var splittedAirportName = remove(split(fullNameAirport, /[,-]/), function (current) {
            return current !== '';
        });
        var cityName = trim(splittedAirportName[splittedAirportName.length - 2]);
        result = cityName + ' (' + trim(splittedAirportName[0]) + ')';
        return result;
    };
    SearchboxNewGenerationComponent.prototype.isEmpty = function (fieldValue) {
        return isEmpty(fieldValue) || isUndefined(fieldValue) || isNull(fieldValue);
    };
    SearchboxNewGenerationComponent.prototype.isNotEmpty = function (fieldValue) {
        return !isEmpty(fieldValue) && !isUndefined(fieldValue) && !isNull(fieldValue);
    };
    SearchboxNewGenerationComponent.prototype.openGetQuoteCompleteDialog = function () {
        var _this = this;
        var dialogRef = this.dialog.open(GetQuoteCompleteDialogComponent, {
            width: '570px',
            disableClose: true,
            panelClass: 'complete-dialog-container',
            autoFocus: false
        });
        dialogRef.afterClosed().subscribe(function () {
            _this.router.navigateByUrl('/your-request');
        });
    };
    SearchboxNewGenerationComponent.prototype.submitSearch = function () {
        if (this.activeForm === this.multiCityForm && !this.thirdFlightValid()) {
            return;
        }
        if (this.activeForm.valid && isPlatformBrowser(this.platformId)) {
            this.submit();
        }
    };
    SearchboxNewGenerationComponent.prototype.modelChanged = function () {
        return !isEqual(this.queryFlightParams, this.lastFlight);
    };
    SearchboxNewGenerationComponent.prototype.noWhitespaceValidator = function (control) {
        var isWhitespace = (control.value || '').trim().length === 0;
        var isValid = !isWhitespace;
        return isValid ? null : { 'isEmpty': true };
    };
    SearchboxNewGenerationComponent.prototype.submit = function () {
        var _this = this;
        this.fillQueryFlightParams();
        if (!isUndefined(this.queryFlightParams.cabin) && !isUndefined(this.queryFlightParams.passengers)) {
            if (this.modelChanged()) {
                this.formSending = true;
                this.disableButton();
                this.trackingService.getTrack().subscribe(function () {
                    _this.broadcaster.next(new TrackingEvent());
                    _this.searchboxService.submitSearch(_this.queryFlightParams).subscribe(function (response) {
                        _this.formSending = false;
                        _this.enableButton();
                        if (!isNil(response.data.error)) {
                            alert(response.data.error);
                        }
                        else {
                            // We lost dial code after contact form hid, to prevent it store code before (WSF-1766)
                            if (_this.normalFlight === true && response.data.normal === false) {
                                _this.contactData.cCode = _this.dialCode;
                                _this.currentCountryIso2Code = _this.getIso2Code();
                            }
                            _this.normalFlight = response.data.normal;
                            // restore dial code (WSF-1766)
                            setTimeout(function () {
                                if (_this.phoneSelect) {
                                    if (_this.isMobileOrTablet) {
                                        if (!Object.keys(_this.phoneSelect.getCountryData()).length) {
                                            _this.phoneSelect.setCountry(_this.currentCountryIso2Code || 'us');
                                        }
                                    }
                                    else {
                                        _this.setCountry(_this.contactData.cCode);
                                    }
                                }
                            });
                            _this.flightSubmitted = true;
                            _this.lastFlight = clone(_this.queryFlightParams);
                        }
                    }, function (error) {
                        _this.formSending = false;
                        _this.enableButton();
                    });
                }, function () {
                    _this.formSending = false;
                    _this.enableButton();
                    _this.showWarnNoty();
                });
            }
        }
    };
    SearchboxNewGenerationComponent.prototype.fillQueryFlightParams = function () {
        this.setFlights();
    };
    SearchboxNewGenerationComponent.prototype.setFlights = function () {
        var flightCount = this.queryFlightParams.tripType === 'MultiCity' ?
            ((isUndefined(this.flight2.from) || isEmpty(this.flight2.from)) ? 2 : 3) : 1;
        var flights = new Array(flightCount);
        this.setFromTo(flights, flightCount);
        this.setDepart(flights, flightCount);
        if (this.queryFlightParams.tripType === 'Round') {
            this.setReturnDate();
        }
        this.queryFlightParams.flights = flights;
    };
    SearchboxNewGenerationComponent.prototype.setFromTo = function (flights, flightCount) {
        flights[0] = new Flight;
        flights[0].from = this.flight.from;
        flights[0].to = this.flight.to;
        if (flightCount >= 2) {
            flights[1] = new Flight;
            flights[1].from = this.flight1.from;
            flights[1].to = this.flight1.to;
        }
        if (flightCount === 3) {
            flights[2] = new Flight;
            flights[2].from = this.flight2.from;
            flights[2].to = this.flight2.to;
        }
    };
    SearchboxNewGenerationComponent.prototype.setDepart = function (flights, flightCount) {
        var departDate = this.flight.departDate;
        flights[0].departDate = this.formatDate(departDate);
        if (flightCount >= 2) {
            var departDate1 = this.flight1.departDate;
            flights[1].departDate = this.formatDate(departDate1);
        }
        if (flightCount === 3) {
            var departDate2 = this.flight2.departDate;
            flights[2].departDate = this.formatDate(departDate2);
        }
    };
    SearchboxNewGenerationComponent.prototype.formatDate = function (date) {
        return date.month + '/' + date.day + '/' + date.year;
    };
    SearchboxNewGenerationComponent.prototype.setReturnDate = function () {
        var returnDate = this.returnDate;
        this.queryFlightParams.returnDate = this.formatDate(returnDate);
    };
    SearchboxNewGenerationComponent.prototype.disableButton = function () {
        this.submitDisabled = true;
        this.submitBtnText = 'Loading...';
    };
    SearchboxNewGenerationComponent.prototype.enableButton = function () {
        this.submitDisabled = false;
        this.submitBtnText = 'Get Quote';
    };
    SearchboxNewGenerationComponent.prototype.setCountry = function (countryCode) {
        var currentCountry = filter(document.getElementsByClassName('country'), function (country) {
            return eq(countryCode, country.getAttribute('data-dial-code'));
        });
        if (currentCountry.length > 0) {
            this.phoneSelect.setCountry(currentCountry[0].getAttribute('data-country-code'));
        }
    };
    SearchboxNewGenerationComponent.prototype.getCountryData = function () {
        return this.phoneSelect.getCountryData();
    };
    SearchboxNewGenerationComponent.prototype.changeCabinType = function (cabinType) {
        this.queryFlightParams.cabin = cabinType;
        if (this.activeForm.valid) {
            this.submitSearch();
        }
    };
    SearchboxNewGenerationComponent.prototype.changeTripType = function (tripType, currentForm) {
        this.queryFlightParams.tripType = tripType;
        this.activeForm = currentForm;
        this.focusOut = false;
        this.clickTripType = true;
    };
    SearchboxNewGenerationComponent.prototype.changeTravelers = function () {
        if (this.activeForm.valid) {
            this.submitSearch();
        }
    };
    /* Close Datepicker */
    SearchboxNewGenerationComponent.prototype.onClickedOutsideDp = function (event, dpId, inputId) {
        if (this.mustClose(event, inputId)) {
            if (includes(inputId, 'Depart1')) {
                this.activeDepart1 = false;
            }
            else if (includes(inputId, 'Depart')) {
                this.activeDepart = false;
            }
            else {
                this.activeReturn = false;
            }
            dpId.close();
        }
    };
    SearchboxNewGenerationComponent.prototype.mustClose = function (event, inputId) {
        var inDatepicker = false;
        each(event.target.classList, function (className) {
            if (includes(className, 'ngb') || eq(className, 'text-muted')) {
                inDatepicker = true;
            }
        });
        if (this.isNotEmpty(this.getElementById(inputId))) {
            return !(this.getElementById(inputId).contains(event.target) || inDatepicker);
        }
        else {
            return false;
        }
    };
    SearchboxNewGenerationComponent.prototype.getElementById = function (elementId) {
        return document.getElementById(elementId);
    };
    SearchboxNewGenerationComponent.prototype.getElementByClass = function (elementClass) {
        return document.getElementsByClassName(elementClass);
    };
    SearchboxNewGenerationComponent.prototype.showWarnNoty = function () {
        this.notifyService.warn('Can\'t process your request now. Please try later.', {
            closeWith: ['click', 'button'],
            timeout: 10000
        });
    };
    SearchboxNewGenerationComponent.prototype.executeRecaptchaCheckAction = function () {
        var _this = this;
        this.recaptchaV3Service.execute('submit')
            .pipe(takeUntil(this.destroy$))
            .subscribe(function (token) {
            if (token) {
                _this.getQuote(token);
            }
        });
    };
    SearchboxNewGenerationComponent.prototype.getQuote = function (token) {
        var _this = this;
        if (this.queryPanelForm.get('phone').hasError('phoneFormatError')) {
            this.notifyService.warn('Incorrect area code', {
                closeWith: ['click', 'button'],
                timeout: 5000
            });
        }
        if (!this.activeForm.valid) {
            this.searchFormValid = false;
            this.queryPanelValid = false;
            return;
        }
        if (!this.flightSubmitted) {
            this.submitSearch();
            this.showWarnNoty();
            return;
        }
        if (this.queryPanelForm.valid) {
            this.formSending = true;
            this.disableButton();
            this.preparePhone();
            this.searchFormValid = true;
            this.queryPanelValid = true;
            this.contactData.recaptchaToken = token;
            this.trackingService.getTrack().subscribe(function () {
                _this.searchboxService.sendRequest(_this.contactData).subscribe(function (response) {
                    _this.formSending = false;
                    _this.enableButton();
                    console.debug('Get quote: ' + response.data.details);
                    if (eq(response.status, 'FAILED')) {
                        alert(response.data.details);
                    }
                    else {
                        /* Lead
                         Track when a user expresses interest in your offering (ex. form submission, sign up for trial, landing on pricing page) */
                        // fbq('track', 'Lead');
                        _this.openGetQuoteCompleteDialog();
                    }
                }, function (error) {
                    console.warn('Can\'t process your request now. Please try later.');
                    _this.enableButton();
                    _this.formSending = false;
                });
            }, function () {
                _this.formSending = false;
                _this.enableButton();
                _this.showWarnNoty();
            });
        }
        else {
            this.queryPanelValid = false;
        }
    };
    SearchboxNewGenerationComponent.prototype.preparePhone = function () {
        this.contactData.cCode = this.dialCode;
        this.contactData.phone = replace(this.contactData.phone, new RegExp('[ ()-]', 'g'), '');
    };
    Object.defineProperty(SearchboxNewGenerationComponent.prototype, "dialCode", {
        get: function () {
            var dialCode = this.phoneSelect.getCountryData().dialCode;
            return !isNil(dialCode) && dialCode !== '0' ? dialCode : '1';
        },
        enumerable: true,
        configurable: true
    });
    SearchboxNewGenerationComponent.prototype.getIso2Code = function () {
        var dialCode = this.phoneSelect.getCountryData().iso2;
        return !isNil(dialCode) ? dialCode : 'us';
    };
    SearchboxNewGenerationComponent.prototype.onClickedOutsideTripContent = function (event) {
        if (eq(event.target.className, 'BusinessClassClick') ||
            eq(event.target.className, 'fa fa-angle-down')) {
            return;
        }
        this.tripContent = false;
    };
    SearchboxNewGenerationComponent.prototype.onClickedOutsideCabinType = function (event) {
        if (eq(event.target.className, 'cabinClick') ||
            eq(event.target.className, 'cabinClick ng-star-inserted') ||
            eq(event.target.className, 'cabinClick fa fa-angle-down')) {
            return;
        }
        this.cabinContent = false;
    };
    SearchboxNewGenerationComponent.prototype.onClickedOutsideTripType = function (event) {
        if (eq(event.target.className, 'tripClick') ||
            eq(event.target.className, 'tripClick ng-star-inserted') ||
            eq(event.target.className, 'tripClick fa fa-angle-down')) {
            return;
        }
        this.tripTypeContent = false;
    };
    SearchboxNewGenerationComponent.prototype.onClickedOutsidePassengers = function (event) {
        if (eq(event.target.className, 'passengersClick') ||
            eq(event.target.className, 'passengersClick fa fa-angle-down')) {
            return;
        }
        this.passengersContent = false;
    };
    SearchboxNewGenerationComponent.prototype.initReturnDatepicker = function () {
        if (!this.isEmptyDate(this.flight.departDate)) {
            var date = new Date(this.flight.departDate.year, this.flight.departDate.month - 1, this.flight.departDate.day);
            this.minDateReturn = this.prepareDate(moment(date).add(1, 'd').toDate());
        }
    };
    SearchboxNewGenerationComponent.prototype.initDepartDatapicker = function () {
        if (!this.isEmptyDate(this.flight.departDate)) {
            var date = new Date(this.flight.departDate.year, this.flight.departDate.month - 1, this.flight.departDate.day);
            this.minDateDepart1 = this.prepareDate(moment(date).toDate());
        }
        if (!this.isEmptyDate(this.flight1.departDate)) {
            var date = new Date(this.flight1.departDate.year, this.flight1.departDate.month - 1, this.flight1.departDate.day);
            this.minDateDepart2 = this.prepareDate(moment(date).toDate());
        }
    };
    SearchboxNewGenerationComponent.prototype.openHowItWorksDialog = function () {
        var _this = this;
        this.dataService.sendData({ type: 'showModal', data: true });
        var dialogRef = this.dialog.open(HowItWorksDialogComponent, {
            panelClass: 'how-it-works-dialog'
        });
        dialogRef.afterClosed().subscribe(function (result) {
            _this.dataService.sendData({ type: 'showModal', data: false });
            console.trace('The dialog was closed');
        });
    };
    SearchboxNewGenerationComponent.prototype.handleClick = function () {
        if (isPlatformBrowser(this.platformId)) {
            this.fitCountrySelect();
            this.initFlightDataNow = false;
            this.clickTripType = false;
        }
    };
    SearchboxNewGenerationComponent.prototype.fitCountrySelect = function () {
        var phoneElem = this.getElementByClass('intl-tel-input')[0];
        if (!isNil(phoneElem)) {
            var width = phoneElem.offsetWidth;
            var countryListElem = this.getElementByClass('country-list')[0];
            if (!isNil(countryListElem)) {
                countryListElem.style.width = width + 'px';
            }
        }
    };
    SearchboxNewGenerationComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (isPlatformBrowser(this.platformId)) {
            setTimeout$(function () {
                _this.submitSearch();
                _this.fitCountrySelect();
                /* Set contact info */
                _this.searchboxService.getInitContactData().subscribe(function (response) {
                    if (!isNull(response.data.contactInfo.email)) {
                        _this.contactData = response.data.contactInfo;
                        var splittedPhone = split(response.data.contactInfo.phone, ' ');
                        if (splittedPhone.length > 1) {
                            _this.contactData.phone = splittedPhone[1].replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
                            _this.setCountry(replace(splittedPhone[0], '+', ''));
                        }
                    }
                });
            });
        }
    };
    SearchboxNewGenerationComponent.prototype.onBlur = function (id) {
        var blurredElement = getElementById(id);
        setTimeout$(function () { return blurredElement.blur(); }, 0);
    };
    SearchboxNewGenerationComponent.prototype.addFlight = function () {
        this.showAddFlightButton = false;
        this.showThirdFlight = true;
    };
    SearchboxNewGenerationComponent.prototype.ngOnDestroy = function () {
        // unsubscribe to ensure no memory leaks
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.destroy$.next();
        this.destroy$.unsubscribe();
    };
    SearchboxNewGenerationComponent.prototype.onFocusOutAirportInput = function (event) {
        if (isNil(event.relatedTarget) || (!isNil(event.relatedTarget) && !includes(event.relatedTarget.className, 'mat-option'))) {
            this.changeMustSubmit(true);
            if (this.formSending === false) {
                this.submitSearch();
            }
        }
    };
    SearchboxNewGenerationComponent.prototype.jumpToNextControl = function (element, autocompleteId) {
        this.changeMustSubmit(true);
        if (element && !this.initFlightDataNow && !this.clickTripType) {
            if (element instanceof HTMLInputElement) {
                this.setTimeout$(element);
            }
            else {
                var nativeElement = element._elRef.nativeElement;
                if (element && (includes(nativeElement.id, 'Return') || includes(nativeElement.id, 'Depart'))) {
                    setTimeout$(function () {
                        var currentAutocomplete = document.getElementById(autocompleteId);
                        if (currentAutocomplete) {
                            currentAutocomplete.blur();
                        }
                        element.toggle();
                    }, 100);
                }
            }
        }
    };
    SearchboxNewGenerationComponent.prototype.setTimeout$ = function (element) {
        var _this = this;
        setTimeout$(function () {
            if (_this.formSending) {
                interval(10)
                    .pipe(takeWhile(function () { return _this.formSending; }))
                    .subscribe(function () {
                    setTimeout$(function () { return element.click(); }, 100);
                    // This will be called every 10 milliseconds until `formSending` flag is set to false
                });
            }
            else {
                setTimeout$(function () { return element.click(); }, 100);
            }
        }, 100);
    };
    SearchboxNewGenerationComponent.prototype.capitalize = function (value) {
        return capitalize(value);
    };
    Object.defineProperty(SearchboxNewGenerationComponent.prototype, "withForm", {
        get: function () {
            return !this.router.url.includes('faq') &&
                !this.router.url.includes('about-us') &&
                !this.router.url.includes('terms-and-conditions') &&
                !this.router.url.includes('terms-and-conditions-app') &&
                !this.router.url.includes('insurance-terms') &&
                !this.router.url.includes('privacy-policy') &&
                !this.router.url.includes('corporate') &&
                !this.router.url.includes('reviews') &&
                !this.router.url.includes('our-team') &&
                !this.router.url.includes('your-request') &&
                !this.router.url.includes('privacy-app') &&
                !this.router.url.includes('contact-us');
        },
        enumerable: true,
        configurable: true
    });
    SearchboxNewGenerationComponent.prototype.countryChanged = function () {
        this.queryPanelForm.get('phone').updateValueAndValidity();
    };
    SearchboxNewGenerationComponent.prototype.isEmptyDate = function (date) {
        return date ? isNaN(date.day) || isNaN(date.month) || isNaN(date.year) : true;
    };
    return SearchboxNewGenerationComponent;
}());
export { SearchboxNewGenerationComponent };
